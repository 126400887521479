import '@styles/widgets/notification-box.scss';

jQuery(function () {
   const NotificationBox = {
      init($widget) {
         const dismiss = $widget.find('.ssea-notification-box__dismiss');
         jQuery(dismiss).on('click', function (evt) {
            evt.preventDefault();
            evt.stopPropagation();
            // Find the elementor section that is holding this element.
            const sectionWrapper = $widget.parent().parent().parent().parent();
            jQuery(sectionWrapper).slideUp();
         });
      },
   };

   jQuery(window).on('elementor/frontend/init', () => {
      elementorFrontend.hooks.addAction('frontend/element_ready/ssea-notification-box.default', NotificationBox.init);
   });
});
